import { ProfileSchema } from '@/backend/models/Profile'
import { SocialTypeEnumSchema } from '@/backend/models/Social'
import { createEnum } from '@/libraries/zod/createEnum'
import dayjs from 'dayjs'
import { z } from 'zod'

export const UserRoleEnum = createEnum(z.enum(['admin', 'user']))

export const UserTypeEnum = createEnum(
  z.enum(['creator', 'user', 'agency', 'admin', 'unknown'] as const),
  {
    creator: '크리에이터',
    user: '클라이언트',
    agency: '이너부스 등록',
    admin: '관리자',
    unknown: '알수없음',
  },
)

export const UserCategoryEnum = createEnum(
  z.enum(['individual', 'organization']),
)

export const UserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  username: z.string().nullable(),
  password: z.string().nullable(),
  role: UserRoleEnum.zEnum,
  type: UserTypeEnum.zEnum,
  category: UserCategoryEnum.zEnum,
  createdAt: z.date(),
  isBlocked: z.boolean().default(false),
})

export type User = z.infer<typeof UserSchema>

export const SignUpFormSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    type: UserTypeEnum.zEnum,
    category: UserCategoryEnum.zEnum,
    acceptMarketing: z.boolean().optional(),
  })
  .and(
    z.lazy(() =>
      ProfileSchema.extend({
        displayName: z.string().optional(),
        phoneNumber: z.string().optional(),
      }),
    ),
  )

export type SignUpForm = z.infer<typeof SignUpFormSchema>

export const SocialSignUpFormSchema = z.object({
  email: z.string().email(),
  username: z.string(),
  socialType: SocialTypeEnumSchema,
  socialId: z.string(),
})

export type SocialSignUpForm = z.infer<typeof SocialSignUpFormSchema>

export const UserTypeFormSchema = z.object({
  userId: z.string(),
  type: UserTypeEnum.zEnum,
  category: UserCategoryEnum.zEnum,
  displayName: z.string(),
})

export type UserTypeForm = z.infer<typeof UserTypeFormSchema>

export const UserSchemaForAdmin = z.object({
  id: z.string(),
  userCode: z.string(),
  isVerified: z.boolean(),
  imageURL: z.string().nullable(),
  email: z.string().email(),
  displayName: z.string(),
  humanReadableID: z.string().nullable(),
  type: UserTypeEnum.zEnum,
  date: z.date(),
  followers: z.number(),
})

export type UserListItem = z.infer<typeof UserSchemaForAdmin>

const regions = ['kr', 'other'] as const

export const UserListSearchParamsSchema = z.object({
  search: z.string().default(''),
  page: z.coerce.number().default(1),
  perPage: z.coerce.number().default(10),
  sortBy: z.string().optional().default('date'),
  sortOrder: z.enum(['asc', 'desc']).optional().default('desc'),
  fromDate: z.string().default(new Date('2020-11-23').toISOString()),
  toDate: z.string().default(dayjs().add(1, 'day').toISOString()),
  status: z.preprocess(
    (value) => {
      if (value === undefined) return []
      if (typeof value === 'string') {
        const status = ['blocked']
        return value.split(',').filter((badge) => status.includes(badge))
      }
      return value
    },
    z.array(z.enum(['blocked'])),
  ),

  type: z.preprocess((value) => {
    if (value === undefined) return []
    if (typeof value === 'string') {
      const allowed = UserTypeEnum.toArray().map((a) => a.value)
      return value.split(',').filter((type) => allowed.includes(type))
    }
  }, z.array(UserTypeEnum.zEnum)),
  region: z.preprocess(
    (value) => {
      if (value === undefined) return []
      if (typeof value === 'string') {
        return value
          .split(',')
          .filter((region) => regions.includes(region as any))
      }
      return value
    },
    z.array(z.enum(regions)),
  ),
  badge: z.preprocess(
    (value) => {
      if (value === undefined) return []
      if (typeof value === 'string') {
        const badges = ['yes', 'no']
        return value.split(',').filter((badge) => badges.includes(badge))
      }
      return value
    },
    z.array(z.enum(['yes', 'no'])),
  ),
  isGMS: z.preprocess(
    (value) => {
      if (value === undefined) return []
      if (typeof value === 'string') {
        const isGMS = ['yes', 'no']
        return value.split(',').filter((badge) => isGMS.includes(badge))
      }
      return value
    },
    z.array(z.enum(['yes', 'no'])),
  ),
})

export type UserListSearchParams = z.infer<typeof UserListSearchParamsSchema>

export const AgencyDataSchema = z.object({
  imageURL: z.string().optional().nullable(),
  name: z.string().min(1).max(20),
  introduction: z.string().min(1).max(180),
  socialLinks: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
      url: z.string(),
    }),
  ),
})

export type AgencyDataForm = z.infer<typeof AgencyDataSchema>
